/* css variables for global colors, spaces and timings */
:root {
	/*  color variables */
	--color-black: #000;
	--color-white: #fff;
	--color-green: #090;
	--color-red: #bb0a30;
	--color-progressive-red: #f50537;
	--color-grey-05: #f2f2f2;
	--color-grey-10: #e5e5e5;
	--color-grey-15: #d9d9d9;
	--color-grey-20: #ccc;
	--color-grey-30: #b3b3b3;
	--color-grey-40: #999;
	--color-grey-50: #808080;
	--color-grey-60: #666;
	--color-grey-70: #4c4c4c;
	--color-grey-80: #333;
	--color-grey-90: #1a1a1a;
	/* font-face font-weight */
	--font-weight-bold: 700;
	--font-weight-light: 200;
	--font-weight-normal: 400;
	/* size variables */
	--space-xxs: 3px;
	--space-xs: 6px;
	--space-s: 12px;
	--space-m: 18px;
	--space-l: 24px;
	--space-xl: 30px;
	--space-xxl: 36px;
	--space-xxxl: 42px;
	--space-column: 4%;
	--space-side-margin: 4%;
	/* icon variables */
	--icon-size-small: 24px;
	--icon-size-large: 48px;
	/* timing variables */
	--time-xxs: 30ms;
	--time-xs: 60ms;
	--time-s: 120ms;
	--time-m: 180ms;
	--time-l: 240ms;
	--time-xl: 420ms;
	--time-xxl: 660ms;
	--time-xxxl: 960ms;
	--transition-ease-in: cubic-bezier(.69, .01, .83, .56);
	--transition-ease-out: cubic-bezier(.21, .55, .43, 1);
	--transition-ease-in-out: cubic-bezier(.75, .02, .5, 1);
	/* usage: transition-timing-function: var(--transition-ease-in); */

	/* required for feature-app instructure */
	--max-content-width: 1920px;
	--page-margin: 16px;
	--spacing-relative-2xs: 4px;
	--spacing-relative-xs: 8px;
	--spacing-relative-sm: 12px;
	--spacing-relative-md: 16px;
	--spacing-relative-lg: 24px;
	--spacing-relative-xl: 28px;
	--spacing-relative-2xl: 36px;
	--spacing-relative-3xl: 40px;
	--spacing-relative-4xl: 48px;
	--spacing-relative-5xl: 64px;
	--spacing-relative-6xl: 80px;
	--spacing-relative-7xl: 96px;
	--spacing-relative-8xl: 120px;
}

@media (min-width: 375px) {
	:root {
		/* required for feature app infrastructure */
		--page-margin: 28px;
	}
}

@media all and (min-width: 768px) and (max-width: 1023px) {
	:root {
		--space-xxxl: 48px;

		/* required for feature app infrastructure */
		--page-margin: 40px;
		--spacing-relative-3xl: 48px;
		--spacing-relative-4xl: 64px;
		--spacing-relative-5xl: 72px;
		--spacing-relative-6xl: 88px;
		--spacing-relative-7xl: 104px;
		--spacing-relative-8xl: 136px;
	}
}

@media all and (min-width: 1024px) and (max-width: 1439px) {
	:root {
		--space-xxl: 42px;
		--space-xxxl: 60px;

		/* required for feature app infrastructure */
		--page-margin: 60px;
		--spacing-relative-2xl: 40px;
		--spacing-relative-3xl: 56px;
		--spacing-relative-4xl: 72px;
		--spacing-relative-5xl: 88px;
		--spacing-relative-6xl: 104px;
		--spacing-relative-7xl: 128px;
		--spacing-relative-8xl: 168px;
	}
}

@media all and (min-width: 1440px) and (max-width: 1919px) {
	:root {
		--space-xl: 36px;
		--space-xxl: 54px;
		--space-xxxl: 78px;
		--space-side-margin: 12%;

		/* required for feature app infrastructure */
		--page-margin: 96px;
		--spacing-relative-xl: 32px;
		--spacing-relative-2xl: 56px;
		--spacing-relative-3xl: 72px;
		--spacing-relative-4xl: 88px;
		--spacing-relative-5xl: 104px;
		--spacing-relative-6xl: 120px;
		--spacing-relative-7xl: 160px;
		--spacing-relative-8xl: 216px;
	}
}

@media all and (min-width: 1920px) {
	:root {
		--space-xl: 42px;
		--space-xxl: 66px;
		--space-xxxl: 96px;
		--space-side-margin: 12%;

		/* required for feature app infrastructure */
		--page-margin: 96px;
		--spacing-relative-xl: 40px;
		--spacing-relative-2xl: 72px;
		--spacing-relative-3xl: 88px;
		--spacing-relative-4xl: 104px;
		--spacing-relative-5xl: 120px;
		--spacing-relative-6xl: 136px;
		--spacing-relative-7xl: 176px;
		--spacing-relative-8xl: 248px;
	}
}
